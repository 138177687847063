.tile {
  height: 52px;
  width: 52px;
  border: 2px solid #000;
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 26px;
  text-align: center;
  padding: 4px;
  margin: 4px;
  display: inline-block;
  border-radius: 4px;
  box-shadow: 2px 2px #000;
  background-color: #999;
  color: #000;
  line-height: 1;
  position: absolute;
  top: 0;
  left: 0;
}
.tile .value {
  text-align: right;
  font-size: 12px;
}
.tile[draggable="true"] {
  cursor: move;
  background-color: #CCC;
}
.grid {
  display: grid;
  grid-template-columns: repeat(15, auto);
  width: fit-content;
  border: 1px solid #000;
  background-color: #FFF;
  margin-bottom: 24px;
}
.cell {
  border: 1px solid #000;
  height: 64px;
  width: 64px;
  position: relative;
}
.cell:nth-child(113) {
  background-color: #CDF;
}
.rack .cell {
  border: 0;
}